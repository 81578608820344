import { GiKnifeFork } from "react-icons/gi";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { IoWifi } from "react-icons/io5";
import { GrUserManager } from "react-icons/gr";
import { LuChefHat } from "react-icons/lu";
import { FaSwimmingPool } from "react-icons/fa";
import { GiPowerGenerator } from "react-icons/gi";
import { FaPeopleRoof } from "react-icons/fa6";
import { PiBathtubBold } from "react-icons/pi";
import { PiElevatorFill } from "react-icons/pi";
import { MdOutlineBalcony } from "react-icons/md";
import { FaSunPlantWilt } from "react-icons/fa6";

export const API_URL = "https://sohamvillas.com/web-backend";

export const VillaImages = {
  "HOME MOBILEBANNER": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/04bb7c59-b4f1-4c95-47bb-e189d8d4f400/public",
    }, // soham_m_homeB3.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7397c074-6709-441d-b700-62a00edc5600/public",
    }, // soham_m_homeB4.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/874da198-0933-427a-b037-c749279fb300/public",
    }, // soham_m_homeB6.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/787843fd-587c-4f59-7152-b9a777240500/public",
    }, // soham_m_homeB2.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/be78708f-b3e5-4a88-e15c-edbb92466800/public",
    }, // soham_m_homeB5.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/28f086c5-ce27-4733-6fda-58bc8dca9000/public",
    }, // soham_m_homeB7.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f83383c1-de29-45fd-8917-d1c081bfda00/public",
    }, // soham_m_homeB8.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/63369239-852b-4189-9fdb-2f5c6d23f300/public",
    }, // soham_m_homeB1.webp
  ],

  "HOME BANNER": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a99cdc29-84d4-4e9d-8c1c-84529e9c1200/public",
    }, // soham_homeB3.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/868e1ba3-f740-43b7-36f1-a749f2d88e00/public",
    }, // soham_homeB4.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7393e808-c39e-4b88-d6e2-b6e149102c00/public",
    }, // soham_homeB6.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/903e341f-b261-4b89-7c1e-f918cd616700/public",
    }, // soham_homeB2.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/cf702ceb-28e0-4476-92df-372aa3e47000/public",
    }, // soham_homeB5.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/612ebc75-c267-4021-d9bf-ad7e80ee9400/public",
    }, // soham_homeB7.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/614a12f5-666f-49fb-dcdd-aa2ae0ac2800/public",
    }, // soham_homeB8.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a63508ce-8ad7-41a0-3f7f-b79397483800/public",
    }, // soham_homeB1.webp
  ],

  "VILLA ARCADIA": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/35dbcc1d-9094-4d19-d210-53e192896200/public",
    }, //Arcadia1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0de4c8f2-0bcd-4c9a-d4ed-c200d2234c00/public",
    }, //Arcadia2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6ff01fd4-302c-467a-99e8-aedfcda63e00/public",
    }, //Arcadia3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fdf1d677-4f7c-4197-4d62-6e7770bc7100/public",
    }, //Arcadia4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d032dd1d-204e-482c-43b8-da8465758800/public",
    }, //Arcadia5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/878b6eb4-3cfc-4d46-9a29-409809d24e00/public",
    }, //Arcadia6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e93e41ab-6401-407b-2d3e-f5fee48d2c00/public",
    }, //Arcadia7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0ab3a38f-fe77-4304-3ed2-e6be8dc4ef00/public",
    }, //Arcadia8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2b1882dc-c01c-40e4-9d19-a19c6f2d1600/public",
    }, //Arcadia9.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c394a9bd-131e-41c7-840d-f44f77130400/public",
    }, //Arcadia10.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/32d95654-22e5-48d5-e56b-c1da69ea4800/public",
    }, //Arcadia11.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/97058af6-5779-4dc8-9cdf-bdff66f35a00/public",
    }, //Arcadia12.WEBP
  ],
  "VILLA SERENITY": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8f2ee8b7-6293-44d7-0561-66937cdd9a00/public",
    }, //Serenity1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/371b1a2b-6144-4690-c79d-4480281b6d00/public",
    }, //Serenity2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c77acdd8-6efe-45cd-39de-80cc8ee28800/public",
    }, //Serenity3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/71ab84ce-d1a4-4f93-a2ef-f99d8d857e00/public",
    }, //Serenity4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b5b66508-189a-4415-d9be-a43c3eac2300/public",
    }, //Serenity5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/743f66ef-c796-49fe-1b06-0326b196cb00/public",
    }, //Serenity6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/dab244e9-46f0-48e5-d075-71cc69650100/public",
    }, //Serenity7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2411f8a2-a7be-49b1-da32-4650301d8a00/public",
    }, //Serenity8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ac07deb1-2764-4c6d-9751-7738bc053f00/public",
    }, //Serenity9.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/471ed1d1-4b79-4af3-0a77-dca885152200/public",
    }, //Serenity10.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7cb57072-73ac-4259-d56c-ddaa11ec0e00/public",
    }, //Serenity11.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/74777baa-298e-4c9e-2bc2-e04ddf9eb400/public",
    }, //Serenity12.WEBP
  ],
  "HOUSE OF BASHŌ": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4edc78e2-6ec6-4bd2-5e8b-595541845900/public",
    }, //Basho1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/11a1ebc5-4569-4188-7396-ed251a38b400/public",
    }, //Basho2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6df3dc71-bccf-4cf0-69ec-c5a82dcd8600/public",
    }, //Basho3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/837751d3-feae-4521-3905-5f3190040c00/public",
    }, //Basho4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f2618fd0-2b4f-4731-012b-7e82d4131b00/public",
    }, //Basho5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/bb345579-f6bf-41a6-31a2-fca1c8c38600/public",
    }, //Basho6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a9d56371-5778-4efa-a0d0-5c66e964be00/public",
    }, //Basho7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6a132525-5297-46a3-4300-623023426400/public",
    }, //Basho8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/913f7a73-740a-45f4-356c-d2cc90698700/public",
    }, //Basho9.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9cd34a81-7370-4fbf-4410-f256b5940800/public",
    }, //Basho10.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/09c2f345-da72-45bf-62f3-d1f880951900/public",
    }, //Basho11.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c29751b2-c5d6-44dd-5aa9-a9b363e67300/public",
    }, //Basho12.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1548b0ab-b11e-4af1-fbee-609fdc96f800/public",
    }, //Basho13.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a42a79db-015a-443a-ac10-61208d6c6a00/public",
    }, //Basho14.WEBP
  ],
  "VILLA CELEBRATION": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9276b15a-a56d-42ee-3cc1-d5c2f2abef00/public",
    }, //Celebration2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d5c6e27f-d341-4e94-44d4-eb769890a900/public",
    }, //Celebration3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ec0a660b-a00a-436a-21e7-4e1ec10cb100/public",
    }, //Celebration4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6f076d0a-5034-4ac4-5ad1-d64cb04a4d00/public",
    }, //Celebration5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/67cecc0b-61e0-4888-f988-11e9e6ccd600/public",
    }, //Celebration6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1ce5f454-e3e4-43a1-5d75-e5d4f6760900/public",
    }, //Celebration7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/53afc1b9-f5e7-4864-33e1-1b6c52976700/public",
    }, //Celebration9.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f3932dd9-e77e-48df-e731-3bfa2a6ce400/public",
    }, //Celebration10.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8e131569-130e-47a7-dbee-6acd87265700/public",
    }, //Celebration11.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/31bb012e-8725-4afa-92e0-06ab53f66b00/public",
    }, //Celebration12.WEBP
  ],
  "VILLA LUXO": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ebed53d0-5111-4814-ce1d-21fda93a1c00/public",
    }, //Luxo2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/47ad53a6-6394-4241-8d3d-3de6a6edfa00/public",
    }, //Luxo3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9f8ac1d5-3af4-4b62-596a-eba3ef8d4d00/public",
    }, //Luxo4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/204d7f51-3a1b-4de1-aead-b805b2cf4300/public",
    }, //Luxo5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e45e808e-ee45-4368-22d2-f25009327b00/public",
    }, //Luxo6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1c78d02b-be72-41c8-534c-af5c81514800/public",
    }, //Luxo7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/880e0d37-4b1a-438a-d980-bee25c193400/public",
    }, //Luxo8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fccfd692-0589-460f-b035-ad4ad6aa5b00/public",
    }, //Luxo9.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8a652b9b-f718-45a8-8a50-867cad853e00/public",
    }, //Luxo1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/62b5f9b3-3e99-4e74-9d1a-f5854039c000/public",
    }, //Luxo10.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ffd1574c-444f-492b-534c-e7451a8c6300/public",
    }, //Luxo11.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c777b7cf-ecce-433a-335a-ab41ed6d8e00/public",
    }, //Luxo12.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6c48faf9-efbc-4228-cded-ad6b18b25b00/public",
    }, //Luxo13.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2dcb354c-10f1-4c7e-f233-949286cebd00/public",
    }, //Luxo14.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/26510e74-4761-412e-1dba-f515f045cc00/public",
    }, //Luxo15.WEBP
  ],
  "VILLA CHAPORA": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8f7f5ead-f40f-4222-b0b1-7e3e845de500/public",
    }, //Chapora2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8ce4196f-ee1a-4559-f14a-080c1c29c800/public",
    }, //Chapora3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/10911003-63ce-4ebe-734a-d1ebefe02800/public",
    }, //Chapora4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2cfac50b-e2ea-4616-717b-afe019c0f200/public",
    }, //Chapora5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/330d1299-c306-491d-bf41-322bcb11cf00/public",
    }, //Chapora6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/99ee11f0-de84-4d5f-adb3-077a783ab700/public",
    }, //Chapora7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8947b884-7593-40ff-a688-78db97494c00/public",
    }, //Chapora1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e0c70f39-6356-4196-df88-9d12bf88d000/public",
    }, //Chapora8.WEBP
  ],
  "VILLA MANDOVI": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/87556dde-34f5-4fa1-7b19-827e9cbe2100/public",
    }, //Mandovi4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/07a834d1-1fb0-4964-b9ef-126b0ad68100/public",
    }, //Mandovi5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/193520e7-4b78-45dd-0421-35d2fde3a800/public",
    }, //Mandovi2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/94300955-068c-4d1d-c5c1-df98ac86aa00/public",
    }, //Mandovi3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0ee011ba-8cee-4fd8-a7df-06a4b4081100/public",
    }, //Mandovi6.WEBP
    // { src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6d0e7efb-bdc5-4de2-8583-d06150ebd400/public" }, //Mandovi1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b1b02085-3cfb-474a-cdc2-1c47ddafcc00/public",
    }, //Mandovi7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e3f46a08-be76-4536-2aaf-fbac8e06cf00/public",
    }, //Mandovi8.WEBP
  ],
  "VILLA VERISSIMO": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3af2d4ed-b2de-419a-89ba-925db9c18600/public",
    }, //Versimo1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4a36a94c-9821-4096-aac1-ddaed7bd5e00/public",
    }, //Versimo2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fb815455-769a-43db-d2d2-fe3db84cf300/public",
    }, //Versimo3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8587c5e4-9d7c-44de-8fb8-e27ef72d3100/public",
    }, //Versimo4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b032ae3d-cba2-474e-9d41-30131d635c00/public",
    }, //Versimo5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b45d330a-6ffc-48aa-3b2f-661ff1805300/public",
    }, //Versimo6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/450003e6-e47a-472e-e10b-e7d72babf100/public",
    }, //Versimo7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0f0c3baf-0a30-4dad-f5ff-c76aae87d500/public",
    }, //Versimo8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c9332fb8-2a00-4b77-486a-e96ad83bcd00/public",
    }, //Versimo9.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/be8b20c3-a0f0-4006-5ad7-d36f1924c100/public",
    }, //Versimo10.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/aaf2a23d-fef8-4467-0fbe-fa4ac9f6f900/public",
    }, //Versimo11.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e3168fa8-724c-4c1f-9f65-30758ca90c00/public",
    }, //Versimo12.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a38c7781-8a12-42f1-49ba-8126a81fa700/public",
    }, //Versimo13.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d62bb8f4-4f81-4ae3-57aa-eaa27ea69800/public",
    }, //Versimo14.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c2e4dab0-644a-412e-1fc5-709d006ebf00/public",
    }, //Versimo15.WEBP
  ],
  "VILLA SAL": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5cd5d2f9-d7cf-4fe6-e033-ad94e7204000/public",
    }, //Sal1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ad165dfd-675f-42b4-54e1-d75a351d0200/public",
    }, //Sal2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/aea51868-eb71-4ccd-0161-f12799bb0c00/public",
    }, //Sal3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/20a384e1-ff6d-4afb-f083-17b1ad366a00/public",
    }, //Sal4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f646f390-cd11-40d7-bf69-d08e379b5d00/public",
    }, //Sal5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/60ce98d8-b919-412b-f89c-946af9b62400/public",
    }, //Sal6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/91279375-87ea-45b0-82d4-bf031f996500/public",
    }, //Sal7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b078931e-f156-40b9-90ae-c68da182b100/public",
    }, //Sal8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2a84041f-2f57-4028-8c27-7360b6cbb200/public",
    }, //Sal9.WEBP
  ],
  "VILLA ZUARI": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7942f59f-1c68-4c19-d174-fa7744213700/public",
    }, //Zuari1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/61731d38-801a-4c77-fbf1-833b65057300/public",
    }, //Zuari2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/935020e5-c378-4bc5-3298-e55328e5d200/public",
    }, //Zuari3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/f800ddd5-87c9-41fd-21e0-22914f6e9800/public",
    }, //Zuari4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8200f1a3-4c97-463e-dd48-40d9c3461900/public",
    }, //Zuari5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/23ea0d1f-9cb9-4732-69d8-434db0800f00/public",
    }, //Zuari6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b3ab1fee-23d0-4dce-d438-9070d2b11000/public",
    }, //Zuari7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d1d44c03-f8d9-4cde-c37b-3d5de35a0400/public",
    }, //Zuari8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/7098b73e-e751-4519-e1bd-38cb4c90ff00/public",
    }, //Zuari9.WEBP
  ],
  "VILLA VERA G-7": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/63f19bdc-9f23-4e5d-4fed-1fca2114e000/public",
    }, //AQ1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/47bcd8d3-bc24-4f82-3d52-44a0665dc700/public",
    }, //AQ2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/461b11d1-dc12-4e44-d464-b89bd4e39d00/public",
    }, //AQ3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/931dd0c3-4bb3-463e-544f-a0edf3ce9f00/public",
    }, //AQ4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/62ef75a5-c25e-4416-9c27-9da17aa69200/public",
    }, //AQ5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/862ae8c4-89a8-43d6-65de-eb4d60552900/public",
    }, //AQ6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/455a7e74-e9f6-4d9e-2071-e1f7ac94d100/public",
    }, //AQ7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c49e8bf3-1377-4a26-3068-91a1b176a000/public",
    }, //AQ8.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a1f620de-8027-4b7e-c7af-e204d57aa600/public",
    }, //AQ9.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e1b57e22-0d44-4d6d-60a3-1e5643c72300/public",
    }, //AQ10.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/d2337f81-9ab4-487f-2524-d81d8c85ad00/public",
    }, //AQ11.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/da64b5c8-33d4-4253-5817-d87d69ddc400/public",
    }, //AQ12.WEBP
  ],
  "VISION GREENS G-1C": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/feb5f8e1-008e-42f1-e544-4191f9e8d200/public",
    }, //f1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a1af0971-7902-40b5-fd17-dbb2e7807b00/public",
    }, //f2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0dde9b92-2942-4374-ebea-ba3f29a02200/public",
    }, //f3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/dc09a9d0-1eb7-4f53-61b5-42429f1f0100/public",
    }, //f4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c8920319-6029-4602-c9b2-42022b905100/public",
    }, //f5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4f81759b-51b1-48ff-ffd9-f7cd6ae33100/public",
    }, //f6.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5078823f-bce4-4ae1-0d3c-c51b53765c00/public",
    }, //f7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6e29622e-6b83-4f2a-a3ec-02ef2c1c6200/public",
    }, //f8.WEBP
  ],
  "AQUA GRANDIOSA - 201": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/14ed8c8a-5fc9-457a-a1cb-6947377a0500/public",
    }, //VV1.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3221cfd3-a4b1-4d65-d1b8-b501b03d3500/public",
    }, //VV2.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3f15d6fd-f53e-4295-7d14-5dec8a21ce00/public",
    }, //VV3.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/78f4914a-d10c-4538-bcc8-e2e9c52fe300/public",
    }, //VV4.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/785bd261-8291-4fee-af78-79f2dd3d1f00/public",
    }, //VV5.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/873e146c-10e5-49cf-ccfa-4548b8569400/public",
    }, //VV7.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/eae47f7f-2f7c-4116-fdc3-46db0f4b9b00/public",
    }, //VV8.WEBP
  ],

  SATTVA: [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3bf3170b-54df-42fc-9e67-6807b89b8300/public",
    }, //soham_sattva1.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a43d4614-a945-4fd9-2da2-79867bc02500/public",
    }, //soham_sattva2.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/52fcea55-d109-418a-4065-1272e6123500/public",
    }, //soham_sattva3.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c46e475e-87fb-442c-2508-1aaf09a8b600/public",
    }, //soham_sattva4.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/42a5667b-c51b-4821-4088-540c93b87e00/public",
    }, //soham_sattva5.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/37949851-ecac-451c-f208-68ba86923400/public",
    }, //soham_sattva6.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/286c7462-791a-4ddb-1c67-ba6e02284400/public",
    }, //soham_sattva7.webp
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/614a12f5-666f-49fb-dcdd-aa2ae0ac2800/public",
    // }, //soham_sattva8.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5cf2b281-74d8-4b8b-edf8-2d1e0d1c7100/public",
    }, //soham_sattva9.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/318626d4-e6a7-41f7-7c60-4117052f1b00/public",
    }, //soham_sattva10.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/952790cf-7480-4171-5a70-8e25775edc00/public",
    }, //soham_sattva11.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/1bc14c02-6a36-4695-6c8a-fd744198fe00/public",
    }, //soham_sattva12.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/ce74829c-3a18-43ec-a67b-5f6f02679f00/public",
    }, //soham_sattva13.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5f9dcefd-e3c8-48f1-0b70-977bd8022100/public",
    }, //soham_sattva14.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/02b83c91-14ee-4cd3-f67d-b2e88194b600/public",
    }, //soham_sattva15.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4545e11c-0c7f-47ee-8241-2c5449e13e00/public",
    }, //soham_sattva16.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/88fb6d39-22ee-49ff-2c88-dae1e099fe00/public",
    }, //soham_sattva17.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/bb0e03e5-4bf8-405e-1c5f-307b59d25500/public",
    }, //soham_sattva18.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b243f943-9a0e-4ea4-db3a-f1d2f0642300/public",
    }, //soham_sattva19.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/22dce5b2-5682-4d14-905e-d4e9aaf29600/public",
    }, //soham_sattva20.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/2ef1201a-8b5b-4fca-6347-b151b6e16600/public",
    }, //soham_sattva21.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4c76a03a-2990-4599-6112-412c9d751d00/public",
    }, //soham_sattva22.webp
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3612a1b8-122a-4012-388b-77b9c59a9700/public",
    }, //soham_sattva23.webp

    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/439721fb-a23f-40b8-1e7d-3305651c3000/public",
    // }, //satva_11.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/066db8f3-a726-495a-8eac-de134c0f5700/public",
    // }, //satva_01.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/c04613fe-ae33-4205-5817-37b58a18fa00/public",
    // }, //satva_02.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/065a2726-46bd-43c9-1cbc-843bd9f88800/public",
    // }, //satva_06.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/5e33f8e3-485c-46af-fd99-ddb95bf6a400/public",
    // }, //satva_09.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9b4181a6-557a-4e81-8580-f0988063ec00/public",
    // }, //satva_13.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/dac2ef32-f95f-475c-9ad3-d88e6a998e00/public",
    // }, //satva_10.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/39d1600e-a095-4eb8-c509-3dbbac9f4500/public",
    // }, //satva_03.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/fea86673-4170-4f6d-c0bf-d2da06f5ed00/public",
    // }, //satva_04.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/43a7b56c-1e11-4b56-43c9-cac6e0eefa00/public",
    // }, //satva_05.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/def5157a-fea6-44c7-f9e2-957c5217f900/public",
    // }, //satva_08.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/da205a19-530b-4213-f1e0-dde051dc2500/public",
    // }, //satva_12.WEBP
    // {
    //   src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0036c35e-0648-415b-433c-34eb94336e00/public",
    // }, //satva_14.WEBP
  ],
};

export const villaAmenities = {
  "VILLA ARCADIA": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
    { icon: <FaPeopleRoof />, text: "Rooftop Lounge" },
    { icon: <PiBathtubBold />, text: "Jacuzzi" },
    { icon: <PiElevatorFill />, text: "Private Elevator" },
  ],
  "VILLA SERENITY": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
    { icon: <FaPeopleRoof />, text: "Rooftop Lounge" },
    { icon: <PiBathtubBold />, text: "Jacuzzi" },
    // { icon: <PiElevatorFill />, text: 'Private Elevator' },
  ],
  "HOUSE OF BASHŌ": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
    { icon: <PiBathtubBold />, text: "Jacuzzi" },
  ],
  "VILLA CELEBRATION": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
    { icon: <FaPeopleRoof />, text: "Rooftop Lounge" },
    { icon: <PiBathtubBold />, text: "Jacuzzi" },
  ],
  "VILLA LUXO": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "VILLA CHAPORA": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "VILLA MANDOVI": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "VILLA VERISSIMO": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "VILLA SAL": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "VILLA ZUARI": [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "VILLA VERA G-7": [
    { icon: <GrUserManager />, text: "Caretaker" },
    // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
    { icon: <MdOutlineScreenshotMonitor />, text: "Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Basic Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Common Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "VISION GREENS G-1C": [
    { icon: <GrUserManager />, text: "Caretaker" },
    // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
    { icon: <MdOutlineScreenshotMonitor />, text: "Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Basic Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Common Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  "AQUA GRANDIOSA - 201": [
    { icon: <GrUserManager />, text: "Caretaker" },
    // { icon: <LuChefHat />, text: 'Chef on Call (Add-on-Service)' },
    { icon: <MdOutlineScreenshotMonitor />, text: "Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Basic Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Common Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
  ],
  SATTVA: [
    { icon: <GrUserManager />, text: "Caretaker" },
    { icon: <LuChefHat />, text: "Chef on Call (Add-on-Service)" },
    { icon: <MdOutlineScreenshotMonitor />, text: "Smart Television" },
    { icon: <IoWifi />, text: "Wireless Internet" },
    { icon: <GiKnifeFork />, text: "Fully Equipped Kitchen" },
    { icon: <FaSwimmingPool />, text: "Private Pool" },
    { icon: <GiPowerGenerator />, text: "24x7 Power Backup" },
    { icon: <MdOutlineBalcony />, text: "Private Patio" },
    { icon: <FaSunPlantWilt />, text: "Private Garden" },
    { icon: <PiElevatorFill />, text: "Private Elevator" },
  ],
};

export const nearbyServices = {
  "VILLA ARCADIA": [
    {
      name: "Baga Beach",
      rating: 5,
      link: "https://maps.app.goo.gl/XNmUDhsghqqJMTh59",
    },
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "VILLA SERENITY": [
    {
      name: "Baga Beach",
      rating: 5,
      link: "https://maps.app.goo.gl/XNmUDhsghqqJMTh59",
    },
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "HOUSE OF BASHŌ": [
    {
      name: "Club Cabana",
      rating: 5,
      link: "https://maps.app.goo.gl/JJwXhwV6nCvhqW1d6",
    },
    {
      name: "Hammerz Club",
      rating: 5,
      link: "https://maps.app.goo.gl/YGmhCaQjkoxu9fPh9",
    },
    {
      name: "Anjuna Beach",
      rating: 5,
      link: "https://maps.app.goo.gl/AojvDzUenbG6oiUF9",
    },
  ],
  "VILLA CELEBRATION": [
    {
      name: "Baga Beach",
      rating: 5,
      link: "https://maps.app.goo.gl/XNmUDhsghqqJMTh59",
    },
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: " Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "VILLA LUXO": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Anjuna Beach",
      rating: 5,
      link: "https://maps.app.goo.gl/AojvDzUenbG6oiUF9",
    },
  ],
  "VILLA CHAPORA": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "VILLA MANDOVI": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "VILLA VERISSIMO": [
    { name: "Soro Club", rating: 5 },
    { name: "Ozran Beach", rating: 5 },
  ],
  "VILLA SAL": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "VILLA ZUARI": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "VILLA VERA G-7": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "VISION GREENS G-1C": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  "AQUA GRANDIOSA - 201": [
    {
      name: "Next to Radisson Resort Baga",
      rating: 5,
      link: "https://maps.app.goo.gl/BuPETYmpMxzs4wZA9",
    },
    {
      name: "Baba Au Rhum",
      rating: 5,
      link: "https://maps.app.goo.gl/8HoLzxmc6DN1gjwaA",
    },
    {
      name: "Arpora Saturday Night Market",
      rating: 5,
      link: "https://maps.app.goo.gl/nbeM6HDwh4qnPtqn9",
    },
  ],
  SATTVA: [
    {
      name: "Jamun",
      rating: 5,
      link: "https://maps.app.goo.gl/XkPocfBiS5NLbCkEA",
    },
    {
      name: "Sake- The Asian Lounge",
      rating: 5,
      link: "https://maps.app.goo.gl/ta1Vq1TD69DHdTGbA",
    },
    {
      name: "Gunpowder",
      rating: 5,
      link: "https://maps.app.goo.gl/yJj1jnFMvpBUPmhaA",
    },
    {
      name: "Kefi",
      rating: 5,
      link: "https://maps.app.goo.gl/Rjvn3eM4w9eiPx9P6",
    },
    {
      name: "Sublime",
      rating: 5,
      link: "https://maps.app.goo.gl/yHfVi2g825zirqWC7",
    },
  ],
};

export const SafetyAndHygiene = {
  "VILLA ARCADIA": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Smoke Detectors",
    }, //protection_icon.WEBP
  ],
  "VILLA SERENITY": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Smoke Detectors",
    }, //protection_icon.WEBP
  ],
  "HOUSE OF BASHŌ": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
  "VILLA CELEBRATION": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Smoke Detectors",
    }, //protection_icon.WEBP
  ],
  "VILLA LUXO": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
  "VILLA CHAPORA": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
  "VILLA MANDOVI": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
  "VILLA VERISSIMO": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
  "VILLA SAL": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
  "VILLA ZUARI": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
  "VILLA VERA G-7": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Smoke Detectors",
    }, //protection_icon.WEBP
  ],
  "VISION GREENS G-1C": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Smoke Detectors",
    }, //protection_icon.WEBP
  ],
  "AQUA GRANDIOSA - 201": [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Smoke Detectors",
    }, //protection_icon.WEBP
  ],
  SATTVA: [
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Daily Cleaning",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Fire Extinguishers",
    }, //protection_icon.WEBP
    {
      src: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3e2db84f-2e22-414d-32e1-bd6235aaeb00/public",
      text: "Disinfections and Sterilizations",
    }, //protection_icon.WEBP
  ],
};

export const PropertyStarRating = {
  "VILLA ARCADIA": ["/brochures/villa_arcadia.pdf"],

  "VILLA SERENITY": ["/brochures/villa_arcadia.pdf"],

  "HOUSE OF BASHŌ": ["/brochures/villa_arcadia.pdf"],

  "VILLA CELEBRATION": ["/brochures/villa_arcadia.pdf"],

  "VILLA LUXO": ["/brochures/villa_arcadia.pdf"],

  "VILLA CHAPORA": ["/brochures/villa_arcadia.pdf"],

  "VILLA MANDOVI": ["/brochures/villa_arcadia.pdf"],

  "VILLA SAL": ["/brochures/villa_arcadia.pdf"],

  "VILLA ZUARI": ["/brochures/villa_arcadia.pdf"],

  "AQUA GRANDIOSA - 201": [],

  SATTVA: ["/brochures/villa_arcadia.pdf"],
};

export const PropertyReviews = {
  "VILLA ARCADIA": [],

  "VILLA SERENITY": [],

  "HOUSE OF BASHŌ": [],

  "VILLA CELEBRATION": [
    {
      name: "James",
      date: "March, 2024",
      content:
        "A remarkable villa with remarkable hosts. Everything was perfect for our group. Thank you so much! Next time we are in Goa, we will stay again!",
    },
    {
      name: "Rajshekar",
      date: "March, 2024",
      content:
        "Awesome place ….: serene calm special thanks to host Mr Vimal … lovely villa .. and so we’ll maintained and I must say it is much better than what there 5 star resorts in Goa do not offer……. Special mention about the in house chef on hire …. He made awesome breakfast for 2 days ……. Perfect.",
    },
    {
      name: "Namieta",
      date: "August, 2024",
      content:
        "Excellent stay. The location is good if you want to go around town. Chef and caretaker were both very cooperative and helpful. Villa is exactly how it looks in the pictures though the pool is not as big. The interiors are luxurious and very tastefully done up. Overall, a perfect place to unwind.",
    },
    {
      name: "Tarun",
      date: "January, 2024",
      content:
        "We had booked the place for 7 nights and we had a wonderful stay. The place is great with good amenities and location is 10 mins drive from Baga beach. The rooms are as they appear in the photos. The caretaker Krishna took really good care of us. He was very helpful and accommodating. Would totally recommend the place. ",
    },
    {
      name: "Darshit",
      date: "February, 2024",
      content:
        "Amazing villa for large groups. Great spaces to hang out and central location.",
    },
    {
      name: "Kirthi",
      date: "January, 2024",
      content:
        "The villa has beautiful interiors and is very well maintained. The owner responds very well to all the queries and is very helpful with everything. The manager Amir also is very efficient in getting things done for us. Location is also very convenient. Overall a great stay!",
    },
    {
      name: "Mayur",
      date: "December, 2024",
      content:
        "It's a great place to be. Peaceful, Middletown location. Good access to beaches and markets. Would recommend the place. Owners are courteous and responsive. With Pvt Pool and Jacuzzi, this is all season venue for Goa Stay.",
    },
    {
      name: "Lakshmi Narasimha Murthy",
      date: "November, 2024",
      content:
        "Ambiance of Villa Celebration is jubilant. Vimal has personally arranged and helped us to make our Goan stay memorable. Standard of Cleanliness of the place is nice. Staff are friendly. The place is strategically located between the markets & beaches. I wish them all success...",
    },
  ],

  "VILLA LUXO": [],

  "VILLA CHAPORA": [],

  "VILLA MANDOVI": [
    {
      name: "Nitish",
      date: "January, 2024",
      content:
        "Peaceful location, very clean and maintained villa, cleaning staff was available for assistance, fairly nice pool good enough for 6 adults. Close proximity to spots in North goa.",
    },
    {
      name: "Sukant",
      date: "March, 2024",
      content:
        "We had a wonderful stay at soham villas… place is as depicted in the pictures… caretaker vikram was very helpful and available on call.. bedrooms are huge and everything is well maintained… pool is quite nice as well.. overall.. a nice little place if u want to spend quality time with friends and family….. P.S - baga beach is only 5-10 mins drive away",
    },
    {
      name: "Pratisth",
      date: "March, 2024",
      content:
        "It is a great property it is total value for money, pool is very big it can accommodate more that 6 person villa is well maintained. The property is best for bachelor.",
    },
    {
      name: "Aditi",
      date: "July, 2024",
      content:
        "Amazing place with a fantastic private pool, in a really good location. The staff was responsive and helpful all throughout our stay.",
    },
    {
      name: "Cj",
      date: "April, 2024",
      content:
        "Thank you for hosting us. Right from the time we checked in we had a great time. The care taker was kind and helpful. The tree in the backyard really helps when it is really hot weather. The bathrooms were spacious and very clean. Lots of balconies to spend time in private in the comfort of the room or the common area by the pool and living room was a good combination. Even the checkout process was easy and made it good way to end our vacation. Will definitely be going back!",
    },
    {
      name: "Jerilee",
      date: "March, 2024",
      content:
        "The Villa is extraordinary, very beautiful and well decorated. The striking feature is the cleanliness, super condition of the amenities, the helpful caretaker and the super host. This Villa is way above any rating that I can give on Air BnB, it needs to be experienced as words don’t do justice. Thanks to the Super Host, the stay was like the cherry on the cake for our Goa trip. All details provided on the site are accurate. Definitely recommend this place to anyone staying in North Goa",
    },
    {
      name: "Vemulapalli",
      date: "January, 2025",
      content:
        "Good place. Nice response from the host. Definitely a super host. Really loved the accuracy of the response. The domestic help was really amazing.",
    },
    {
      name: "Neha",
      date: "November, 2024",
      content:
        "The best and safe place!!!Couldn’t ask for anything better!!!! The house, bathrooms, kitchen, linens, everything was up to the mark!!!! Had an excellent experience! The chef, housekeeping team everyone was so good at their work!! I Would definitely recommend it to everyone!!!",
    },
    {
      name: "Pravallika",
      date: "January, 2024",
      content: "Extremely clean and spacious. Overall a very nice experience",
    },
  ],

  "VILLA VERISSIMO": [],

  "VILLA SAL": [],

  "VILLA ZUARI": [
    {
      name: "Niharika",
      date: "August, 2024",
      content:
        "Great stay with the interiors up to date and very clean washrooms and kitchen. Cook was provided on request for breakfast and he cooked amazing food. The pool was a good size for a group 6-7 to relax in it. The villa is situated in a serene lane and still about 10-15 mins distance to Assagao, Anjuna and Candolim.",
    },
    {
      name: "Ayush",
      date: "October, 2024",
      content:
        "It was a really nice place to stay at. The cleanliness, the service, the location, everything is just perfect. The villa supports you with anything you need and helps you make your stay as comfortable as possible.",
    },
    {
      name: "Rishabh",
      date: "December, 2024",
      content:
        "The best villa you can go to have an amazing family time in Goa. Me and my family had an amazing experience staying at the villa",
    },
    {
      name: "November",
      date: "November, 2024",
      content:
        "Very well maintained property, Vimal is a good host who ensured all my requests were accommodated. Special thanks to his staff Ms Babitha, who went out of her way to ensure our stay was perfect.",
    },
    {
      name: "Tony",
      date: "March, 2024",
      content: "The Villa exceeded our expectations!",
    },
  ],

  "VILLA VERA G-7": [
    {
      name: "Vaishnavi",
      date: "January, 2025",
      content:
        "We had a wonderful stay at Soham Villas for 3 nights! The place was really nice—clean, comfortable, and well-maintained. The staff was incredibly friendly and made our stay even better. Shanku, our helper made sure the place was clean. Akash also helped us with a smooth check-in and check-out process, which was much appreciated. Overall, it's a fantastic place to stay, and we highly recommend it!",
    },

    {
      name: "Shivam",
      date: "August, 2024",
      content:
        "Absolutely fantastic stay in this awesome flat. It was spotlessly clean and had everything you could possibly need. It is exactly the same as shown in the pictures. A kitchen with all the items you’d need and all very clean and well presented. We would definitely recommend this flat to those who are looking peaceful property in the main city.",
    },
    {
      name: "Abhishek",
      date: "October, 2024",
      content: "Amazing experience and great vibe",
    },
  ],

  "AQUA GRANDIOSA - 201": [
    {
      name: "Aditya",
      date: "March, 2024",
      content:
        "Great place to stay in North Goa! All the famous places are nearby. The host and the caretaker are extremely nice. Highly recommend!",
    },
    {
      name: "Sahil",
      date: "March, 2024",
      content:
        "The property was almost exactly the same as listed. It was secure in a society with security. It had pool and playing area for kids. Overall, the property was clean and well maintained. It was an amazing experience.",
    },
    {
      name: "Abhishek",
      date: "October, 2024",
      content:
        "Everything about the apartment is perfect. Our caretaker rajendra kumar is very helpful and took care of of us very well. Location is nearby to all famous party places. Overall great stay.",
    },
    {
      name: "Saurabh",
      date: "December, 2024",
      content:
        "Great place to be.. all the good clubs and beaches are nearby.. there is a grocery shop adjacent to building who provides two-wheeler on rent as well.. so it was very convenient. apartment has 2 rooms with attached washrooms. super clean and maintained. our caretaker was also very polite and was available on call. the building had a 24*7 restaurant in its premises, which was serving decent food. apartment is highly recommended to group of friends and families as well.",
    },
    {
      name: "Rawal",
      date: "November, 2024",
      content:
        "One of the best decisions before planning for Goa was to book this place for my family for 4 days. And, it exceeded the expectations in every aspect. Peaceful surroundings which is perfect for a family stay( with kids) . Countless food options and easy to reach as well. Baga beach: 5 min drive, Anjuma: 10 min drive, Senquirem: 15 min, Candolim: 10 min, Calangute: 10 min drive. Special mention to Rajan who was there taking care of every need and Vimal ji for being an amazing host.",
    },
    {
      name: "Sai Abhinav",
      date: "February, 2024",
      content:
        "Comfortable place for 5 people.Flat is neat and clean.24 hours security.Value for money.It is located in very accessible area for all needs.Good parking space.Attendant will be on reach.Host is very accommodative.",
    },
    {
      name: "Mansi",
      date: "February, 2024",
      content:
        "Love the charm of Soham Villas Airbnb. It doesn't have the same feel as a typical chain hotel. Every time we stepped in the door, they were friendly and inquired about our stay. They were also able to propose venues and provide contacts for any assistance we needed with tour bookings. The super-hosts, Vimal and Laxman, called us and made sure we had a great day. Rajiv, the caretaker, was the friendliest person I've ever met at an Airbnb. The ambience and service were excellent. If you want to have a nice time, this is a must do. Excellent location. Everything is within 10-minute drive. Great restaurants were within walking distance. I'd stay there again in a heartbeat.",
    },
    {
      name: "Aparna",
      date: "December, 2024",
      content:
        "The apartment is a great option to stay in North Goa. It has sufficient space for 4 people. Very good location. The help provided is with all the information and help we needed.",
    },
    {
      name: "Sumit",
      date: "November, 2024",
      content:
        "It was a premium apartment with all the amenities. Caretaker was very proactive. He provided us with all the necessities and ad-hoc ration stuff. The location of apartment made it very attractive and peaceful.",
    },
    {
      name: "Shweta",
      date: "February, 2024",
      content:
        "We were 4 girls for our friend’s bachelorette party. It’s super safe the stay was amazing people are very friendly. There’s no restriction. We felt like home. The housekeeper Dilip is very helpful and nice person. Overall value for money. Made our stay memorable.",
    },
    {
      name: "Mridul",
      date: "March, 2024",
      content:
        "it was wonderful stay, i would like to recommend this place for location, cleanness, spacious, peace, homely environment and with all required kitchen accessories.",
    },
  ],

  SATTVA: [],
};

export const PropertyBrochures = {
  "VILLA ARCADIA": ["../assets/Brochures/Villa_Arcadia.pdf"],

  "VILLA SERENITY": ["../assets/Brochures/Villa_Serenity.pdf"],

  "HOUSE OF BASHŌ": ["../assets/Brochures/HouseOfBasho.pdf"],

  "VILLA CELEBRATION": ["../assets/Brochures/Villa_Celebration.pdf"],

  "VILLA LUXO": ["../assets/Brochures/Villa_Luxo.pdf"],

  "VILLA CHAPORA": ["../assets/Brochures/Villa_Chapora.pdf"],

  "VILLA MANDOVI": ["../assets/Brochures/Villa_Mandovi.pdf"],

  "VILLA SAL": ["../assets/Brochures/Villa_Sal.pdf"],

  "VILLA ZUARI": ["../assets/Brochures/Villa_Zuari.pdf"],

  "AQUA GRANDIOSA - 201": [],

  SATTVA: ["../assets/Brochures/Villa_Sattva.pdf"],
};
