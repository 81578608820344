import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Loader from "./components/Home/Loader";
import { HelmetProvider } from "react-helmet-async";

// import { usePageTracking } from './hooks/usePageTracking';
// import TestBookVilla from './components/TestBookVilla/TestBookVilla';

const Home = lazy(() => import("./components/Home/Home"));
const Header = lazy(() => import("./components/Header/Header"));
const Footer = lazy(() => import("./components/Home/Home"));
const BookVilla = lazy(() => import("./components/BookVilla/BookVilla"));
const OurStory = lazy(() => import("./components/OurStory/OurStory"));
const Blogs = lazy(() => import("./components/Blogs/Blogs"));
const Apartments = lazy(() => import("./components/Apartments/Apartments"));
const ContactUs = lazy(() => import("./components/ContactUs/ContactUs"));
const Experiences = lazy(() => import("./components/Experiences/Experiences"));
const FAQs = lazy(() => import("./components/FAQs/FAQs"));
const Terms = lazy(() => import("./components/Terms/Terms"));
const PrivacyPolicy = lazy(() =>
  import("./components/PrivacyPolicy/PrivacyPolicy")
);
const RefundPolicy = lazy(() =>
  import("./components/RefundPolicy/RefundPolicy")
);
const ShortFooter = lazy(() => import("./components/ShortFooter/ShortFooter"));
const Page404 = lazy(() => import("./components/404Page/Page404"));
const Account = lazy(() => import("./components/Account/Account"));
const ViewBooking = lazy(() => import("./components/ViewBooking/ViewBooking"));
const Checkout = lazy(() => import("./components/Checkout/Checkout"));
const SignIn = lazy(() => import("./components/SignIn/SignIn"));
const ForgotPassword = lazy(() =>
  import("./components/ForgotPassword/ForgotPassword")
);
const VillaDetails = lazy(() =>
  import("./components/VillaDetails/VillaDetails")
);
const ConciergeServices = lazy(() =>
  import("./components/ConciergeServices/ConciergeServices")
);
const BookingConfirmed = lazy(() =>
  import("./components/BookingConfirmed/BookingConfirmed")
);
const BookingCancelled = lazy(() =>
  import("./components/BookingCancelled/BookingCancelled")
);
const PropertyManagement = lazy(() =>
  import("./components/PropertyManagement/PropertyManagement")
);
const BestLuxuryVillasInGoaForHoliParty = lazy(() =>
  import("./components/Blogs/BestLuxuryVillasInGoaForHoliParty")
);
const BestPrivateVillaWithSwimmingPoolInGoaForMarchGetaways = lazy(() =>
  import(
    "./components/Blogs/BestPrivateVillaWithSwimmingPoolInGoaForMarchGetaways"
  )
);
const ThingsToKnowBeforeChoosingLuxuryVillaInGoaForAGroupStay = lazy(() =>
  import(
    "./components/Blogs/ThingsToKnowBeforeChoosingLuxuryVillaInGoaForAGroupStay"
  )
);

const ExperienceLuxuryAndComfortWhyA4BhkVillaInGoaIsPerfectForYourGroupRetreat =
  lazy(() =>
    import(
      "./components/Blogs/ExperienceLuxuryAndComfortWhyA4BhkVillaInGoaIsPerfectForYourGroupRetreat"
    )
  );

const WhyBookVillaInsteadOfHotelForYourNextVacation = lazy(() =>
  import("./components/Blogs/WhyBookVillaInsteadOfHotelForYourNextVacation")
);

// import Home from './components/Home/Home';
// import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
// import BookVilla from './components/BookVilla/BookVilla';
// import OurStory from './components/OurStory/OurStory';
// import Blogs from './components/Blogs/Blogs';
// import Apartments from './components/Apartments/Apartments';
// import ContactUs from './components/ContactUs/ContactUs';
// import Experiences from './components/Experiences/Experiences';
// import FAQs from './components/FAQs/FAQs';
// import Terms from './components/Terms/Terms';
// import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
// import RefundPolicy from './components/RefundPolicy/RefundPolicy';
// import ShortFooter from './components/ShortFooter/ShortFooter';
// import Error from './components/Error/Error';
// import Account from './components/Account/Account';
// import ViewBooking from './components/ViewBooking/ViewBooking';
// import Checkout from './components/Checkout/Checkout';
// import SignIn from './components/SignIn/SignIn';
// import ForgotPassword from './components/ForgotPassword/ForgotPassword';
// import VillaDetails from './components/VillaDetails/VillaDetails';
// import Blog1 from './components/Blogs/Blog1';
// import Blog2 from './components/Blogs/Blog2';
// import ConciergeServices from './components/ConciergeServices/ConciergeServices';
// import BookingConfirmed from './components/BookingConfirmed/BookingConfirmed';
// import BookingCancelled from './components/BookingCancelled/BookingCancelled';

function App() {
  // usePageTracking();

  return (
    <div>
      <HelmetProvider>
        <Router>
          <AuthProvider>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Home" element={<Home />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/Footer" element={<Footer />} />
                <Route path="/BookVilla" element={<BookVilla />} />
                <Route path="/OurStory" element={<OurStory />} />
                <Route path="/Blogs" element={<Blogs />} />
                <Route path="/Apartments" element={<Apartments />} />
                <Route path="/ContactUs" element={<ContactUs />} />
                <Route path="/Experiences" element={<Experiences />} />
                <Route path="/FAQs" element={<FAQs />} />
                <Route path="/Terms" element={<Terms />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/RefundPolicy" element={<RefundPolicy />} />
                <Route path="/ShortFooter" element={<ShortFooter />} />
                <Route path="/Account" element={<Account />} />
                <Route path="/ViewBooking" element={<ViewBooking />} />
                <Route path="/Checkout" element={<Checkout />} />
                <Route path="/SignIn" element={<SignIn />} />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                <Route path="/VillaDetails" element={<VillaDetails />} />
                <Route
                  path="/best-luxury-villas-in-goa-for-holi-party"
                  element={<BestLuxuryVillasInGoaForHoliParty />}
                />
                <Route
                  path="/best-private-villa-with-swimming-pool-in-goa-for-march-getaways"
                  element={
                    <BestPrivateVillaWithSwimmingPoolInGoaForMarchGetaways />
                  }
                />
                <Route
                  path="/things-to-know-before-choosing-luxury-villa-in-goa-for-a-group-stay"
                  element={
                    <ThingsToKnowBeforeChoosingLuxuryVillaInGoaForAGroupStay />
                  }
                />
                <Route
                  path="/experience-luxury-and-comfort-why-a-4-bhk-villa-in-goa-is-perfect-for-your-group-retreat"
                  element={
                    <ExperienceLuxuryAndComfortWhyA4BhkVillaInGoaIsPerfectForYourGroupRetreat />
                  }
                />
                <Route
                  path="/why-book-a-villa-instead-of-a-hotel-for-your-next-vacation"
                  element={<WhyBookVillaInsteadOfHotelForYourNextVacation />}
                />

                <Route
                  path="/ConciergeServices"
                  element={<ConciergeServices />}
                />
                <Route
                  path="/BookingConfirmed"
                  element={<BookingConfirmed />}
                />
                <Route
                  path="/BookingCancelled"
                  element={<BookingCancelled />}
                />
                <Route
                  path="/PropertyManagement"
                  element={<PropertyManagement />}
                />

                {/* <Route path='/TestBookVilla' element={<TestBookVilla />} /> */}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Suspense>
          </AuthProvider>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
